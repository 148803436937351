@import 'src/modules/shared/_colors.sass'
@import 'src/modules/shared/_constants.sass'

$tooltip-deviation: 100px
$module-name: custom-tooltip-with-portal

.#{$module-name}
    position: relative
    top: 2px
    white-space: normal
    width: min-content

    &__children
        cursor: pointer
        height: 22px
        width: 22px

    &__popover
        background: $white
        border: 1px solid $black-shade-17
        border-radius: $default-border-radius
        box-shadow: 1px 1px 1px $gray-shade-40
        box-sizing: border-box
        color: $dark-gray
        font-size: 16px
        line-height: 24px
        padding: 11px 26px 11px 17px
        position: absolute
        text-align: left
        transform: translateX(calc(-50% + #{$tooltip-deviation}))
        width: 200px
        z-index: 20000

        &::after
            background: $white
            border: 1px solid $black-shade-17
            border-left: 0
            border-top: 0
            content: ''
            height: 10px
            position: absolute
            right: calc(50% - 6px)
            width: 10px

        &--top

            &::after
                bottom: -6px
                transform: rotate(45deg)

        &--top-right
            transform: translateX(calc(-20% + #{$tooltip-deviation}))

            &::after
                bottom: -6px
                right: calc(80% - 6px)
                transform: rotate(45deg)

        &--top-left

            &::after
                bottom: -6px
                right: calc(20% - 6px)
                transform: rotate(45deg)

        &--bottom
            height: max-content
            top: 32px

            &::after
                top: -6px
                transform: rotate(225deg)

        &--bottom-right
            height: max-content
            top: 32px
            transform: translateX(calc(-20% + #{$tooltip-deviation}))

            &::after
                right: calc(80% - 6px)
                top: -6px
                transform: rotate(225deg)

        &--bottom-left
            height: max-content
            top: 32px
            transform: translateX(calc(-80% + #{$tooltip-deviation}))

            &::after
                right: calc(20% - 8px)
                top: -6px
                transform: rotate(225deg)

        &-close-icon
            cursor: pointer
            opacity: 0.65
            position: absolute
            right: 6px
            top: 6px
            transition: opacity 0.3s

        &:hover
            opacity: 1
